<template>
  <div>
    <div class="head py-4 mb-10">
      <h2
        class="text-center top-text"
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        Technology Firm Introduces Healthcare Product
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <div>
          <img src="../../assets/images/image 11 (1).png" alt="" width="100%" />
        </div>
        <div class="date d-flex justify-space-between pt-7 pb-3">
          <p class=" ma-0" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
          <!-- <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class="body-1 font-weight-bold">Date: April 1, 2019</p>
          </span> -->
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            Technology Firm Introduces Healthcare Product
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class="body-1 font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class="body-1 font-weight-bold">Date: April 1, 2019</p>
            </span>
          </div>
          <p class="pb-2">
            Originally from <span>thisdaylive.com</span> and
            <span>allafrica.com</span>
          </p>
          <p>
            A technology firm, Gricd, has built a smart cold chain box to
            actively keep temperature sensitive items regulated during
            transportation and storage. <br /><br />
            The solution was created to improve the current method of
            transporting temperature sensitive items as well as ensuring the
            safety of the items when transported, by providing real time
            monitoring of the items in the box and monitor its temperature
            remotely. <br /><br />
            According to the Chief Executive Officer, Gricd, Oghenetega Iortim,
            who spoke during an interview with THISDAY, the sensitive items are
            stored and transported using Styrofoam boxes and ice packs which are
            largely inefficient. <br /><br />
            Iortim said, “In contrast, our solution maintains fixed pre-set
            temperatures and communicates vital information while being
            transported, such as the storage temperature, location of device and
            other features necessary for last mile delivery planning. <br />
            “Some of the challenges in the system today include low routine
            immunisation due to ineffective methods of transporting vaccines and
            blood to its designated destination.” <br /><br />
            He also explained that with the Gricd solution, these items would be
            readily and easily accessible in difficult to reach regions, adding
            that in building the solution, the firm followed best practices to
            ensure data security. Iortim added: “In contrast to what is
            obtainable in the market, our product is cheaper. Our solution is
            built for the African and Middle East Asian region, which have
            relatively smaller health budgets. <br /><br />
            “Globally about 19.4 million infants do not have access to vaccines
            and due to the lack of cold chain storage or last mile solutions 40
            per cent of maternal deaths occur in areas without access to blood.”
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2>Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2>Our Categories</h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class="body-1 ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{hover}">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
        <!-- <v-carousel interval="4000" hide-delimiters show-arrows-on-hover cycle>
          <v-carousel-item v-for="(color, i) in 1" :key="i">
            <v-row >
              <div v-for="(m, i) in lastCard" :key="i">
                <v-card
                  class=" mb-10 card-cont"
                  max-width="400"
                  width="400"
                  outlined
                >
                  <v-img :src="m.img" height="300px"> </v-img>
                  <v-card-title>
                    {{ m.content }}
                  </v-card-title>

                  <v-card-actions>
                    <v-btn
                      color="black"
                      style="font-weight: 300;font-size: 11px;"
                      text
                    >
                      Read More
                    </v-btn>
                    <v-spacer></v-spacer>
                    <p class="pr-3 date">10 March, 2021</p>
                  </v-card-actions>
                </v-card>
              </div>
            </v-row>
          </v-carousel-item>
        </v-carousel> -->
      </v-row>
    </div>
  </div>
</template>

<script>
import img1 from '../../assets/images/Rectangle 25.png';
import img2 from '../../assets/images/Rectangle 26.png';
import img3 from '../../assets/images/Rectangle 27.png';
import image4 from '../../assets/images/image (7).png';
import image5 from '../../assets/images/image (10).png';
import image6 from '../../assets/images/image (9).png';
export default {
  data: () => ({
    info: ['News', 'Health', 'Logistics'],
    rightSide: [
      {
        img: img1,
        content: 'Curbing Losses In The Food Value Chain In Africa',
        date: '29 April, 2019',
        link: '/blog/curbing-losses-in-the-food-value-chain-in-africa',
      },
      {
        img: img2,
        content: 'Technology Firm Introduces Healthcare Product',
        date: '01 April, 2019',
        link: '/blog/technology-firm-introduces-healthcare-product',
      },
      {
        img: img3,
        content:
          'The Brilliance Of Nigeria’s Gricd Frij On Eliminating Food And Drug Wastes',
        date: '07 March, 2021',
        link:
          '/blog/the-brilliance-of-nigeria’s-gricd-frij-on-eliminating-food-and-drug-wastes',
      },
    ],
    lastCard: [
      {
        img: image4,
        content: 'Curbing Losses In The Food Value Chain In Africa',
        title: 'Agriculture',
        link: '/blog/curbing-losses-in-the-food-value-chain-in-africa',
      },
      {
        img: image6,
        content:
          'The Brilliance Of Nigeria’s Gricd Frij On Eliminating Food And Drug Wastes',
        title: 'Logistics',
        link:
          '/blog/the-brilliance-of-nigeria’s-gricd-frij-on-eliminating-food-and-drug-wastes',
      },
      {
        img: image5,
        content: 'Technology Firm Introduces Healthcare Product',
        title: 'Healthcare',
        link: '/blog/technology-firm-introduces-healthcare-product',
      },
    ],
    mySVG: require('../../assets/Logo/search.svg'),
  }),

  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://gricd.com/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Blog',
                item: 'https://gricd.com/blog',
              },
               {
                '@type': 'ListItem',
                position: 3,
                name: 'Technology Firm Introduces Healthcare Product',
                item: 'https://gricd.com/blog/technology-firm-introduces-healthcare-product',
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 5rem 0;
  @media (max-width: 1024px) {
    padding: 3rem 0;
  }
}
h2 {
  font-size: 1.7rem !important;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
p span {
  text-decoration: underline;
  color: #28a84a;
}
a {
  text-decoration: none !important;
  color: black;
}
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}
.popular ::v-deep .v-btn--active {
  background-color: none !important;
  background: none !important;
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }
  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
}
</style>
